<template>
  <div class="row">
    <div class="col">
      <table
        class="table table-sm"
        :class="{ 'table-responsive': $store.state.display.sm }"
      >
        <thead>
          <th>Pieza</th>
          <th>Fecha</th>
          <th>Estado</th>
          <th>Caras</th>
          <th>Tratamiento</th>
          <th v-show="showPrices" class="text-right">Precio</th>
          <th></th>
        </thead>
        <tbody>
          <template
            v-for="(l, i) in statusFiltered"
            :class="{ 'table-danger': l.to_make }"
          >
            <tr :key="l.id" v-show="!piece || l.piece == piece">
              <td :title="l.id">{{ pieceName(l.piece) }}</td>
              <td>{{ l.created_at }}</td>
              <td :title="l.created_at">
                <!-- {{ status_list[l.status].name }} -->
                {{ l.status }}
              </td>
              <td>
                <span v-for="(l1, i) in listFaces(l)" :key="i">{{ l1 }},</span>
              </td>
              <td>{{ l.product_name }}</td>
              <td class="text-right" v-show="showPrices">
                <app-span-money
                  :quantity="l.product_unit_price"
                  :moneyCode="l.money_code"
                ></app-span-money>
              </td>
              <td class="d-print-none" v-show="!disabled">
                <div class="btn-group">
                  <button
                    class="btn btn-light btn-sm"
                    type="button"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    :title="l.comment"
                    v-show="l.comment"
                  >
                    <i class="far fa-comment"></i>
                  </button>
                  <button
                    class="btn btn-light btn-sm"
                    type="button"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Eliminar"
                    @click="$emit('deleteItem', { id: l.id, i })"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { pieceName, listFaces } from "./tooth-utils";

export default {
  props: {
    status: {},
    piece: {},
    disabled: {
      default: false
    },
    showPrices: {
      default: false
    },
    filterBy: {
      default: ""
    }
  },
  methods: {
    pieceName,
    listFaces
  },
  computed: {
    statusFiltered() {
      return this.status.filter(() => {
        return true;
      });
    },
    status_list() {
      return this.$store.state.config.dental
        ? this.$store.state.config.dental.odontogram.teeth_status_list
        : [];
    }
  }
};
</script>

<style></style>
