export class TeethHistoryModel{
  
  constructor(
    {
    id,
    created_at,
    updated_at,
    user_id,
    enable,
    piece,
    product_id,
    comment,
    status,
    overhall,
    lingual,
    palatino,
    distal,
    mesial,
    oclusal,
    vestibular,
    odontogram_id,
  } = {}
  ) {

    this.id = id;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.user_id = user_id;
    this.enable = enable;
    this.piece = piece;
    this.product_id = product_id;
    this.comment = comment;
    this.status = status;
    this.overhall = overhall;
    this.lingual = lingual;
    this.palatino = palatino;
    this.distal = distal;
    this.mesial = mesial;
    this.oclusal = oclusal;
    this.vestibular = vestibular;
    this.odontogram_id = odontogram_id;
  }

  getOverhall() { return this.overhall }
  setOverhall(val) { 
    this.overhall = val;
    this.lingual = undefined;
    this.palatino = undefined;
    this.distal = undefined;
    this.mesial = undefined;
    this.oclusal = undefined;
    this.vestibular = undefined;
  }
}