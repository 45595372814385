<template>
  <div>
    <app-switch v-model="seeTemporals">Temporales</app-switch>
    <Teeth ref="teeth" :seeTemporals="seeTemporals"></Teeth>
  </div>
</template>

<script>
import Teeth from "./Teeth";

export default {
  components: {
    Teeth
  },
  data: () => ({
    seeTemporals: false,
  }),
  // props: {
  //   seeTemporals: {
  //     default: false
  //   }
  // },
  methods: {
    getSelectedList() {
      return this.$refs.teeth.getSelectedList();
    },
    reset() {
      this.$refs.teeth.reset();
    }
  }
};
</script>

<style>
</style>
