<template>
  <multiselect
    v-model="val"
    placeholder
    selectLabel
    selectedLabel
    deselectLabel
    :options="colors"
    :searchable="false"
    :allow-empty="false"
  >
    <template slot="singleLabel" slot-scope="{ option }">
      <span :style="'background-color: ' + option.v">
        {{ option.v }}
      </span>
    </template>
    <template slot="option" slot-scope="{ option }">
      <span :style="'background-color: ' + option.v">
        {{ option.v }}
      </span>
    </template>
  </multiselect>
</template>

<script>
export default {
  props: {
    value: {}
  },
  data: () => ({
    val: {}
  }),
  watch: {
    val(to) {
      this.$emit("input", to.k);
    },
    value(to) {
      if (this.val.k != to) {
        this.colors.map((x) => {
          if (x.k == to) {
            this.val = x;
          }
        });
      }
    }
  },
  computed: {
    // val: {
    //   get() {
    //     return this.colors[this.value];
    //   },
    //   set(to) {
    //     this.$emit("input", to.k);
    //   }
    // },
    // valueChild: {
    //   get() {
    //     return this.value;
    //   },
    //   set(val) {
    //     this.$emit("input", val);
    //   }
    // },

    // oColors() {
    //   return this.$store.state.config.dental
    //     ? this.$store.state.config.dental.odontogram.teeth_flag_colors
    //     : {};
    // },
    colors() {
      return this.$store.state.config.dental
        ? this.$store.state.config.dental.odontogram.teeth_flag_colors.map(
            (x, i) => ({ k: i, v: x })
          )
        : [];
    }
  },
  methods: {
    reset() {
      this.val = {};
    }
  }
};
</script>

<style></style>
