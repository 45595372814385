<template>
  <div>
    <div class="app_dco_teeth">
      <Tooth ref="p18" :piece="18" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p17" :piece="17" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p16" :piece="16" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p15" :piece="15" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p14" :piece="14" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p13" :piece="13" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p12" :piece="12" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p11" :piece="11" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p21" :piece="21" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p22" :piece="22" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p23" :piece="23" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p24" :piece="24" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p25" :piece="25" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p26" :piece="26" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p27" :piece="27" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p28" :piece="28" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
    </div>
    <div class="app_dco_teeth" v-show="seeTemporals">
      <Tooth ref="p55" :piece="55" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p54" :piece="54" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p53" :piece="53" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p52" :piece="52" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p51" :piece="51" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p61" :piece="61" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p62" :piece="62" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p63" :piece="63" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p64" :piece="64" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p65" :piece="65" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
    </div>
    <div class="app_dco_teeth" v-show="seeTemporals">
      <Tooth ref="p85" :piece="85" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p84" :piece="84" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p83" :piece="83" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p82" :piece="82" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p81" :piece="81" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p71" :piece="71" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p72" :piece="72" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p73" :piece="73" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p74" :piece="74" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p75" :piece="75" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
    </div>
    <div class="app_dco_teeth">
      <Tooth ref="p48" :piece="48" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p47" :piece="47" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p46" :piece="46" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p45" :piece="45" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p44" :piece="44" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p43" :piece="43" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p42" :piece="42" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p41" :piece="41" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p31" :piece="31" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p32" :piece="32" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p33" :piece="33" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p34" :piece="34" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p35" :piece="35" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p36" :piece="36" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p37" :piece="37" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
      <Tooth ref="p38" :piece="38" :options="options" @thEvent="handleToothEvent($event)"></Tooth>
    </div>
    <!-- <pre :key="countView">{{ teethSelected }} </pre> -->
  </div>
</template>

<script>
import Tooth from "./Tooth";
import { TeethHistoryModel } from "../odontogram/teeth-model";

export default {
  components: { Tooth },
  data: () => ({
    options: {},
    teethSelected: {},
    countView: 1,
    // seeTemporals: false,
  }),
  props: {
    seeTemporals: {
      default: false
    }
  },
  methods: {
    reset() {
      for(let it in this.$refs){
        this.$refs[it].reset();
      }
      this.teethSelected = {};
    },
    handleToothEvent(e) {
      if (e.event == "updateStateFaces") {
        let teethHistory = new TeethHistoryModel();
        if (this.teethSelected[e.piece] != undefined) {
          teethHistory = this.teethSelected[e.piece];
        } else {
          teethHistory.piece = e.piece;
          this.teethSelected[e.piece] = teethHistory;
        }
        teethHistory[e.face] = e.status;
      } else if (e.event == "setOverhall") {
        let teethHistory = new TeethHistoryModel();
        if (this.teethSelected[e.piece] != undefined) {
          teethHistory = this.teethSelected[e.piece];
        } else {
          teethHistory.piece = e.piece;
          this.teethSelected[e.piece] = teethHistory;
        }
        teethHistory.setOverhall(e.status);
      }
      this.countView++;
    },
    getSelectedList() {
      let list = [];
      for (let it in this.teethSelected) {
        list.push(this.teethSelected[it]);
      }
      return list.filter(x => {
        let th = new TeethHistoryModel(x);
        return (
          th.overhall ||
          th.lingual ||
          th.palatino ||
          th.distal ||
          th.mesial ||
          th.oclusal ||
          th.vestibular
        );
      });
    }
  }
};
</script>

<style>
.app_dco_teeth {
  text-align: center;
}
</style>
