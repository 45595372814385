<template>
  <select
    class="custom-select"
    v-model="valueChild"
    v-show="!dontShowIfNotSup || (list.data ? list.data.length > 0 : false)"
  >
    <option :value="undefined"></option>
    <option v-for="(l, index) in list.data" :key="index" :value="l.supplier_id">
      <span>{{ l.supplier_name }}</span> &nbsp;
      <app-span-money
        :quantity="l.price"
        :moneyCode="l.moneyCode"
      ></app-span-money>
    </option>
  </select>
</template>

<script>
import { LogisticService } from "../service";
export default {
  data: () => ({
    list: {}
  }),
  props: {
    supplierCategorieId: {},
    value: {},
    product_id: {
      required: true
    },
    dontShowIfNotSup: {
      default: false
    }
  },
  watch: {
    product_id: {
      immediate: true,
      handler() {
        LogisticService.getSupplierProducts({
          supplier_categorie_id: this.supplierCategorieId,
          product_id: this.product_id
        }).then((res) => (this.list = res));
      }
    }
  },
  mounted() {
    //
  },
  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style></style>
