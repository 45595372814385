<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col text-center">
            <div class="app_odo_t_con">
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p18"
                :piece="18"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p17"
                :piece="17"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p16"
                :piece="16"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p15"
                :piece="15"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p14"
                :piece="14"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p13"
                :piece="13"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p12"
                :piece="12"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p11"
                :piece="11"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p21"
                :piece="21"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p22"
                :piece="22"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p23"
                :piece="23"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p24"
                :piece="24"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p25"
                :piece="25"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p26"
                :piece="26"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p27"
                :piece="27"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p28"
                :piece="28"
              ></Tooth>
            </div>
          </div>
        </div>
        <div v-show="seeTempTeeth" class="row">
          <div class="col text-center">
            <div class="app_odo_t_con">
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p55"
                :piece="55"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p54"
                :piece="54"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p53"
                :piece="53"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p52"
                :piece="52"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p51"
                :piece="51"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p61"
                :piece="61"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p62"
                :piece="62"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p63"
                :piece="63"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p64"
                :piece="64"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p65"
                :piece="65"
              ></Tooth>
            </div>
          </div>
        </div>
        <div v-show="seeTempTeeth" class="row">
          <div class="col text-center">
            <div class="app_odo_t_con">
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p85"
                :piece="85"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p84"
                :piece="84"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p83"
                :piece="83"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p82"
                :piece="82"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p81"
                :piece="81"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p71"
                :piece="71"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p72"
                :piece="72"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p73"
                :piece="73"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p74"
                :piece="74"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p75"
                :piece="75"
              ></Tooth>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <div class="app_odo_t_con">
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p48"
                :piece="48"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p47"
                :piece="47"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p46"
                :piece="46"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p45"
                :piece="45"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p44"
                :piece="44"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p43"
                :piece="43"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p42"
                :piece="42"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p41"
                :piece="41"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p31"
                :piece="31"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p32"
                :piece="32"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p33"
                :piece="33"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p34"
                :piece="34"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p35"
                :piece="35"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p36"
                :piece="36"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p37"
                :piece="37"
              ></Tooth>
              <Tooth
                @showPiece="showPiece($event)"
                @marked="addPieceToMarke($event)"
                @unmarked="removePieceMarked($event)"
                @clickMenu="handleMenu($event)"
                :options="teethOptions"
                ref="p38"
                :piece="38"
              ></Tooth>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="position-fixed" ref="">Muestra</div> -->
    </div>
  </div>
</template>

<script>
import { TeethHistoryModel } from "./teeth-model.js";
import { isToothPiece } from "./tooth-utils";
import Tooth from "./Tooth";

export default {
  components: {
    Tooth
  },

  // directives
  // filters

  props: {
    disabled: {
      default: false
    },
    seeTempTeeth: {
      default: true
    },
    mode: {
      default: 1
    },
    group: {
      default: 1
    }
  },

  data: () => ({
    pieces: [],
    tempStatus: {
      product_id: 0
    }
  }),

  computed: {
    teethOptions() {
      return {
        mode: this.mode /** 1 => normal, 2 => multiselect */,
        group: this.group,
        disabled: this.disabled
      };
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    removePieceMarked(piece) {
      this.pieces.splice(this.pieces.indexOf(piece), 1);
    },

    addPieceToMarke(piece) {
      this.pieces.push(piece);
      // console.log("add", piece);
      // if (this.pieces.includes(piece)) {
      //   console.log("ya esta dentro", this.pieces.indexOf(piece));
      //   this.pieces.splice(this.pieces.indexOf(piece), 1);
      // } else {
      //   this.pieces.push(piece);
      // }
    },

    /**
     * @public
     */
    addStatus(status) {
      var val = new TeethHistoryModel(status);
      if (isToothPiece(val.piece)) {
        this.$refs["p" + val.piece].addStatus(val);
        // this.$refs["p" + val.piece].setMarked(false);
      }
    },
    /**
     * @public
     */
    reset() {
      this.pieces = [];
      for (const key in this.$refs) {
        if (this.$refs[key].reset) {
          this.$refs[key].reset();
        }
      }
    },

    resetMarkeds() {
      this.pieces = [];
      for (const key in this.$refs) {
        if (this.$refs[key].reset) {
          this.$refs[key].setMarked(false);
        }
      }
    },

    showPiece(e) {
      this.$emit("showPiece", e);
    },
    handleMenu(e) {
      

      if (e.piece == 2) {
        this.$emit("mode2", {
          ...e,
          pieces: this.pieces
        });
      } else {
        this.$emit("mode1", {
          ...e,
          pieces: this.pieces
        });
      }
    }
    // /** @public */ setMode(mode, teethStatus) {
    //   console.log("status", teethStatus);
    //   this.tempStatus = teethStatus;
    //   this.teethOptions.mode = mode;
    // },
    // /** @public */ setGroup(group) {
    //   this.teethOptions.group = group;
    // }
  }
};
</script>

<style>
.app_odo_t_con {
  display: inline-block;
}
</style>
