<template>
  <div class="app_dco_tooth">
    <ToothBoxes ref="toothBoxes" @set="setFaces($event)" :disabled="setOverhall"></ToothBoxes>
    <span>{{ piece }}</span>
    <app-checkbox class="ml-2" v-model="setOverhall" placeholder></app-checkbox>
  </div>
</template>

<script>
import ToothBoxes from "./ToothBoxes";
import { getFaceFromPieceBoxCord } from "../odontogram/tooth-utils";

export default {
  components: { ToothBoxes },
  props: {
    piece: {},
    options: {}
  },
  data: () => ({
    setOverhall: false
  }),
  watch: {
    setOverhall(to) {
      this.$refs.toothBoxes.setAll(to ? 1 : 0);
      this.$emit("thEvent", {
        event: "setOverhall",
        piece: this.piece,
        face: "overhall",
        status: to ? 1 : 0
      });
    }
  },
  methods: {
    reset() {
      this.setOverhall = false;
      this.$refs.toothBoxes.reset();
    },
    setFaces({ status, faceNum }) {
      this.$emit("thEvent", {
        event: "updateStateFaces",
        piece: this.piece,
        face: getFaceFromPieceBoxCord(this.piece, faceNum),
        status: status
      });
    }
  }
};
</script>

<style>
@import url("./tooth.css");
</style>
