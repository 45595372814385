<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Pieza</label>
      <input type="text" class="form-control" :value="piece" disabled />
      <!-- <app-small-form-errors :errors="errors."></app-small-form-errors> -->
    </div>
    <div class="form-group" v-show="showStatusMember">
      <label for>Estado</label>
      <input
        type="text"
        class="form-control"
        :value="statusMember.name"
        disabled
      />
      <app-small-form-errors :errors="errors.status"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>Producto</label>
      <SelectProduct v-model="product_id" ref="selpro"></SelectProduct>
      <app-small-form-errors
        :errors="errors.product_id"
      ></app-small-form-errors>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-9 w-auto">
          <label for>Caras</label>
          <div class="form-inline">
            <app-checkbox
              class="mr-2"
              id="tf_cb_00"
              :disabled="statusMember.overhall"
              v-model="overhall"
              placeholder="Toda la pieza"
            ></app-checkbox>
            <template v-if="!statusMember.overhall">
              <app-checkbox
                class="mr-2"
                id="tf_cb_12"
                v-model="lingual"
                placeholder="Lingual"
              ></app-checkbox>
              <app-checkbox
                class="mr-2"
                id="tf_cb_21"
                v-model="palatino"
                placeholder="Palatino"
              ></app-checkbox>
              <app-checkbox
                class="mr-2"
                id="tf_cb_22"
                v-model="distal"
                placeholder="Distal"
              ></app-checkbox>
              <app-checkbox
                class="mr-2"
                id="tf_cb_23"
                v-model="mesial"
                placeholder="Mesial"
              ></app-checkbox>
              <app-checkbox
                class="mr-2"
                id="tf_cb_32"
                v-model="oclusal"
                placeholder="Oclusal"
              ></app-checkbox>
              <app-checkbox
                class="mr-2"
                id="tf_cb_44"
                v-model="vestibular"
                placeholder="Vestibular"
              ></app-checkbox>
            </template>
          </div>
          <app-small-form-errors :errors="errors.faces"></app-small-form-errors>
        </div>
        <div class="col" v-show="config.can_set_flag">
          <label for>Color</label>
          <SelectFlagColor ref="selColor" v-model="flag"></SelectFlagColor>
        </div>
      </div>
    </div>
    <div class="form-group"></div>
    <div class="form-group">
      <label for>Comentario</label>
      <app-textarea v-model="comment"></app-textarea>
    </div>
    <div>
      <app-button-submit class="mr-1" :disabled="errors"></app-button-submit>
      <button class="btn btn-light" @click.prevent="$emit('cancel')">
        Cancelar
      </button>
    </div>
  </form>
</template>

<script>
import SelectProduct from "../../store-module/products/Select";
import * as validate from "validate.js";
import SelectFlagColor from "./SelectFlagColor";
import { mapState } from "vuex";
import { Observable } from "rxjs";

validate.validators.custom = function(
  value,
  requiredSetFaces,
  key,
  attributes
) {
  if (requiredSetFaces) {
    if (
      attributes.overhall ||
      attributes.lingual ||
      attributes.palatino ||
      attributes.distal ||
      attributes.mesial ||
      attributes.oclusal ||
      attributes.vestibular
    ) {
      return null;
    } else return "Selecciona al menos una cara de la pieza";
  }
  return null;
};

const formRules = function(
  requiredSetProduct = false,
  requiredSetFaces = false
) {
  return {
    piece: { presence: { message: "Requerido" } },
    status: { presence: { message: "Requerido" } },
    product_id: {
      presence: requiredSetProduct ? { message: "Requerido" } : undefined
    },
    faces: { custom: requiredSetFaces }
  };
};

export default {
  components: {
    SelectProduct,
    SelectFlagColor
  },
  props: {
    showStatusMember: {
      default: false
    },
    requiredSetProduct: {
      default: false
    }
  },
  data: () => ({
    piece: undefined,
    status: undefined,

    /** faces */
    overhall: undefined,
    lingual: undefined,
    palatino: undefined,
    distal: undefined,
    mesial: undefined,
    oclusal: undefined,
    vestibular: undefined,

    comment: undefined,
    product_id: undefined,
    flag: undefined,
    requiredSetFaces: false
  }),
  watch: {
    overhall: {
      handler(to) {
        if (to) {
          this.lingual = false;
          this.palatino = false;
          this.distal = false;
          this.mesial = false;
          this.oclusal = false;
          this.vestibular = false;
        }
      }
    },
    lingual(to) {
      if (to) {
        this.overhall = false;
      }
    },
    palatino(to) {
      if (to) {
        this.overhall = false;
      }
    },
    distal(to) {
      if (to) {
        this.overhall = false;
      }
    },
    mesial(to) {
      if (to) {
        this.overhall = false;
      }
    },
    oclusal(to) {
      if (to) {
        this.overhall = false;
      }
    },
    vestibular(to) {
      if (to) {
        this.overhall = false;
      }
    }
  },
  computed: {
    ...mapState({
      config: (s) => s.config.dental.odontogram
    }),
    statusMember() {
      let list = this.config ? this.config.teeth_status_list : [];
      return list[this.status] ? list[this.status] : {};
    },
    errors() {
      let err = validate(
        this.$data,
        formRules(this.requiredSetProduct, this.requiredSetFaces),
        {
          fullMessages: false
        }
      );
      return err ? err : false;
    }
  },
  methods: {
    reset() {
      this.requiredSetFaces = false;
      if (this.$refs.selpro) this.$refs.selpro.reset();
      this.product_id = undefined;
      this.piece = undefined;
      this.status = undefined;
      this.overhall = undefined;
      this.lingual = undefined;
      this.palatino = undefined;
      this.distal = undefined;
      this.mesial = undefined;
      this.oclusal = undefined;
      this.vestibular = undefined;
      this.comment = undefined;
      this.flag = undefined;
    },
    loadReg({ piece, status, product_id }) {
      return new Observable((s) => {
        this.piece = piece;
        this.status = status;
        this.flag = this.statusMember.flag;

        if (this.statusMember.set_faces) {
          this.requiredSetFaces = true;
          s.next(true);
        } else {
          this.overhall = true;
        }

        if (product_id) {
          this.$refs.selpro.setValueFromId(product_id);
          this.product_id = product_id;
          if (this.overhall) s.complete();
        } else if (this.statusMember.product_id) {
          this.$refs.selpro.setValueFromId(this.statusMember.product_id);
          this.product_id = product_id;
          if (this.overhall) s.complete();
        } else {
          s.next(true);
        }
      });
    },
    setFlag(flag) {
      this.flag = flag;
    },
    save(pieces) {
      if (!this.errors) {
        this.$emit(
          "submit",
          JSON.parse(
            JSON.stringify({
              ...this.$data,
              pieces
            })
          )
        );
      }
    }
  }
};
</script>

<style></style>
