<template>
  <div class="app_dco_tooth_boxes">
    <div class="app_dco_tooth_rbox">
      <div @click="handleClickBox('b12')" ref="b12" class="app_dco_tooth_box"></div>
    </div>
    <div class="app_dco_tooth_rbox">
      <div @click="handleClickBox('b21')" ref="b21" class="app_dco_tooth_box"></div>
      <div @click="handleClickBox('b22')" ref="b22" class="app_dco_tooth_box"></div>
      <div @click="handleClickBox('b23')" ref="b23" class="app_dco_tooth_box"></div>
    </div>
    <div class="app_dco_tooth_rbox">
      <div @click="handleClickBox('b32')" ref="b32" class="app_dco_tooth_box"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    disabled: {
      default: false
    }
  },
  mounted() {
    this.reset();
  },
  computed: {
    ...mapState({
      config: state => state.config.dental.odontogram
    })
  },
  methods: {
    setAll(status) {
      for (let i in this.$refs) {
        this.setColor(i, this.config.teeth_flag_colors[status]);
      }
    },

    handleClickBox(ref) {
      if (this.disabled) return;
      if (this.getColor(ref) == "white") {
        this.setColor(ref, "red");
        this.$emit("set", {
          status: 1,
          faceNum: ref.length == 3 ? ref.substring(1) : ""
        });
      } else {
        this.setColor(ref, "white");
        this.$emit("set", {
          status: 0,
          faceNum: ref.length == 3 ? ref.substring(1) : ""
        });
      }
    },

    setColor(ref, color) {
      this.$refs[ref].style.backgroundColor = color;
    },
    getColor(ref) {
      return this.$refs[ref].style.backgroundColor;
    },
    reset() {
      for (let i in this.$refs) {
        this.setColor(i, "white");
      }
    }
  }
};
</script>

<style>
.app_dco_tooth_box {
  width: 12px;
  height: 12px;
  background-color: white;
  border: 1pt solid rgb(102, 102, 102);
}

.app_dco_tooth_rbox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.app_dco_tooth_boxes {
}
</style>
