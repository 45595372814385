<template>
  <app-modal-basic ref="dModal">
    <div class="form-group">
      <label for>Descuento</label>
      <app-input-number v-model="discount"></app-input-number>
    </div>
    <app-button-submit @click="applyDiscount()"></app-button-submit>
  </app-modal-basic>
</template>
<script>
import { DentalClinicService } from '../service';
export default {
  components: {
    //
  },

  //directives
  //filters

  props: {
    treatment_plan_id: {}
  },

  data: () => ({
    discount: null
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    applyDiscount() {
      DentalClinicService.setTreatmentDiscount({
        treatment_plan_id: this.treatment_plan_id,
        discount: this.discount
      }).then(() => {
        this.$emit('submitted');
        this.$refs.dModal.hide();
      })
    },
    show() {
      this.$refs.dModal.show();
    }
  }
};
</script>

<style></style>