<template>
  <div class="row mt-2" v-show="!disabled">
    <div class="col">
      <button
        class="btn btn-light btn-sm shadow-sm"
        v-for="(l, i) in listFiltered"
        :key="i"
        @click.prevent="$emit('clickMenu', l)"
      >
        <i class="fas fa-plus"></i> {{ l.name }}
      </button>

      <!-- <div class="dropdown">
        <button
          class="btn btn-light dropdown-toggle btn-sm btn-block shadow-sm"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Arcada Superior
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a
            v-for="(l, i) in sup"
            :key="i"
            class="dropdown-item"
            href="#"
            @click.prevent="$emit('clickMenu', l)"
          >
            {{ l.name }}
          </a>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="dropdown">
        <button
          class="btn btn-light dropdown-toggle btn-sm btn-block shadow-sm"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Arcada Inferior
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a
            v-for="(l, i) in inf"
            :key="i"
            class="dropdown-item"
            href="#"
            @click.prevent="$emit('clickMenu', l)"
          >
            {{ l.name }}
          </a>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
// import { mapState } from "vuex";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    disabled: { default: false },
    group: {default: 1}
  },

  data: () => ({
    // sup: [],
    // inf: []
    list: []
  }),

  computed: {
    listFiltered() {
      return this.list.filter(x => !this.group || x.group == this.group);
    },
    // ...mapState({
    //   group: s => s.dental.odo_group
    // })
  },

  watch: {
    //
  },

  mounted() {
    DentalClinicService.getOdontogramMenu({ all: 1 }).then(list => {
      // this.sup = list.filter(x => x.piece == 101);
      // this.inf = list.filter(x => x.piece == 102);

      this.list = list.filter(
        x => x.piece == 0
        //  && (this.group || 1) == x.group
      );
    });
  },

  methods: {
    //
  }
};
</script>

<style></style>
