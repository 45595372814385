<template>
  <div :class="{ 'd-print-none': printableOption }" @mouseleave="hideMenus()">
    <!-- 
     @mouseleave="hideMenus()"
     -->
    <div class="row d-print-none">
      <div class="col text-center">
        <span v-show="disabled" class="text-secondary"> Solo lectura </span>
      </div>
    </div>

    <div class="row">
      <div class="col text-right d-print-none">
        <div class="d-flex">
          <app-switch
            v-model="seeTempTeeth"
            id="seeTempTeeth"
            placeholder="Temporales"
            class="ml-auto"
          ></app-switch>
        </div>
      </div>
    </div>

    <TeethStates
      @clickMenu="saveTeethStatus($event)"
      :disabled="disabled"
    ></TeethStates>

    <div class="row my-2">
      <div class="col">
        <span
          v-for="(l, i) in teethStates"
          :key="i"
          :title="l.name || l.product_name"
          class="mr-2"
        >
          <i
            :class="{
              'text-primary': l.flag == 2,
              'text-danger': l.flag == 1
            }"
            class="fas fa-square"
          ></i>
          {{ l.name || l.product_name }} ({{ l.makedTimes }})
        </span>
      </div>
    </div>

    <Teeth
      ref="elTeeth"
      :seeTempTeeth="seeTempTeeth"
      :disabled="disabled"
      :mode="mode"
      :group="group"
      @showPiece="
        piece = $event;
        $emit('piece', $event);
      "
      @mode1="handleMenu($event)"
      @mode2="
        tempStatus = $event;
        mode = 2;
      "
      @onlyOne="saveTeethStatus($event)"
    ></Teeth>

    <div class="row my-2">
      <div class="col">
        <span
          v-for="(l, i) in teethStatesInf"
          :key="i"
          :title="l.name || l.product_name"
          class="mr-2"
        >
          <i
            :class="{
              'text-primary': l.flag == 2,
              'text-danger': l.flag == 1
            }"
            class="fas fa-square"
          ></i>
          PPR ({{ l.makedTimes }})
        </span>
      </div>
    </div>

    <div class="my-3 text-center" v-show="mode == 2">
      <div class="my-2">
        <small>Seleccionar las piezas involucradas</small>
      </div>
      <button
        :disabled="$refs.elTeeth && $refs.elTeeth.pieces.length == 0"
        class="btn btn-primary btn-sm"
        @click="handleMode2($refs.elTeeth.pieces)"
      >
        Guardar
      </button>
      <button
        class="btn btn-secondary btn-sm ml-1"
        @click="
          mode = 1;
          $refs.elTeeth.resetMarkeds();
        "
      >
        Cancelar
      </button>
    </div>

    <!-- @clickMenu="
        mode = 2;
        tempStatus = $event;
      " -->
    <!-- <teeth-heave
      @toMode2="
        tempStatus = $event;
        mode = 2;
      "
      @clickMenu="handleMenu($event)"
      :disabled="disabled"
    ></teeth-heave> -->

    <div class="row mt-2 mb-3 form-inline" hidden>
      <div class="col">
        <datalist id="app_odo_name_op">
          <option v-for="l in names" :key="'na' + l" :value="l"></option>
        </datalist>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            :value="name"
            @change="name = $event.target.value"
            @keypress.enter="name = $event.target.value"
            placeholder="Nombre del odontograma"
            list="app_odo_name_op"
            :disabled="disabled || !canSetName"
          />
          <div class="input-group-append" v-show="!disabled">
            <button class="btn btn-light" data-toggle="dropdown">
              <i class="fa fa-cog"></i>
            </button>
            <div class="dropdown-menu">
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="handleMenu({ piece: 0, status: 1 })"
                >Nuevo Tratamiento
              </a>
            </div>
          </div>
          <input
            type="text"
            class="d-print-none form-control ml-2"
            v-model="piece"
            placeholder="Pieza"
          />
          <div class="input-group-append d-print-none">
            <button class="btn btn-light" type="button" @click="piece = ''">
              <i class="fa fa-eraser"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <slot></slot>

    <slot name="history" :status="status">
      <TeethHistory
        :status="status"
        :disabled="disabled"
        :piece="piece"
        v-show="seeTeethHistory"
        @deleteItem="
          $refs.dDeleteItem.val = {};
          $refs.dDeleteItem.val.id = $event.id;
          $refs.dDeleteItem.val.i = $event.i;
          $refs.dDeleteItem.show();
        "
        :showPrices="showPrices"
      ></TeethHistory>
    </slot>

    <app-modal-basic ref="dChangeStatus">
      <FormChangeStatus
        ref="fChangeStatus"
        @submit="saveChange($event)"
        @cancel="$refs.dChangeStatus.hide()"
        :requiredSetProduct="requiredSetProduct"
      ></FormChangeStatus>
    </app-modal-basic>

    <app-modal-yn
      ref="dDeleteItem"
      @yes="deleteItem($refs.dDeleteItem.val.id, $refs.dDeleteItem.val.i)"
      @no="$refs.dDeleteItem.hide()"
    >
      <p>Seguro de eliminar el registro?</p>
    </app-modal-yn>
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import FormChangeStatus from "./FormChangeStatus.vue";
import $ from "jquery";
// import SelectFlagColor from "./SelectFlagColor";
import Teeth from "./Teeth.vue";
import TeethHistory from "./TeethHistory.vue";
// import TeethHeave from "./TeethHeave";
// import checkState from "./CheckState";
import TeethStates from "./TeethStates";
import { mapState } from "vuex";

export default {
  components: {
    FormChangeStatus,
    // SelectFlagColor,
    Teeth,
    TeethHistory,
    // TeethHeave,
    // checkState,
    TeethStates
  },

  // directives
  // filters

  props: {
    seeTeethHistory: {
      default: true
    },
    canSetName: {
      default: true
    },
    showPrices: {
      default: false
    },
    disabled: {
      default: false
    },
    requiredSetProduct: {
      default: false
    },
    nameOdontogram: {
      default: ""
    },
    group: {
      default: 1
    }
  },

  data: () => ({
    status: [],
    name: null,
    piece: null,
    names: [],
    flag_color: null,
    seeTempTeeth: false,
    client_id: null,
    // odontogram_id: null,
    money_code: null,
    teethStates: {},
    mode: 1,
    tempStatus: {}, // an temporal object to mode 2
    teethStatesInf: {}
  }),

  computed: {
    printableOption() {
      return !this.$store.state.config.dental.odontogram.printable;
    },
    // money_code() {
    //   return this.status[0] ? this.status[0].product_money_code : undefined;
    // },
    odontogram_id() {
      return this.status.length > 0 ? this.status[0].odontogram_id : undefined;
    },
    // config() {
    //   return this.$store.state.config.dental
    //     ? this.$store.state.config.dental.odontogram
    //     : {};
    // },

    ...mapState({
      config: s => s.config.dental.odontogram
    })
    // totals() {
    //   let totals = {};
    //   for (let un of this.status) {
    //     if (un.product_money_code) {
    //       if (totals[un.product_money_code] == undefined)
    //         totals[un.product_money_code] = {};
    //       totals[un.product_money_code].custom_price =
    //         parseFloat(un.custom_price) +
    //         parseFloat(
    //           totals[un.product_money_code].custom_price
    //             ? totals[un.product_money_code].custom_price
    //             : 0
    //         );
    //       totals[un.product_money_code].price =
    //         parseFloat(un.product_unit_price) +
    //         parseFloat(
    //           totals[un.product_money_code].price
    //             ? totals[un.product_money_code].price
    //             : 0
    //         );
    //     }
    //   }
    //   return totals;
    // },
  },

  watch: {
    group(to) {
      this.$refs.elTeeth.setGroup(to);
    }
  },

  mounted() {
    //
  },

  methods: {
    /** @private */ handleMode2(pieces) {
      this.$refs.elTeeth.resetMarkeds();
      this.mode = 1;

      DentalClinicService.saveToothStatus({
        client_id: this.client_id,
        name: this.name,
        odontogram_id: this.odontogram_id,
        product_id: this.tempStatus.product_id,
        status: this.tempStatus.status || 35,
        pieces
      }).then(list => {
        this.loadStatusList(list);
        this.$emit("mode2Added", list);
      });

      // console.log("data", this.tempStatus.product_id, pieces);
    },

    /** @private */ handleChangeMode() {},

    /**
     * @public
     */
    // setOdontogramId(odontogram_id) {
    //   this.odontogram_id = odontogram_id;
    // },
    /**
     * @public
     */
    getMoneyCode() {
      // console.log('alguien pregunto')
      return this.money_code;
    },
    /**
     * @public
     */
    setSeeTempTeeth(val) {
      this.seeTempTeeth = val;
    },
    /**
     * @public
     */
    setName(name) {
      this.name = name;
    },
    /**
     * @public
     */
    setClientId(client_id) {
      this.client_id = client_id;
    },
    /**
     * @public
     */
    reload() {
      this.getList();
    },
    /**
     * @public
     */
    setFlagColor(val) {
      this.flag_color = val;
    },

    hideMenus() {
      $(".app-odo-t").dropdown("hide");
      $(".app-odo-t").dropdown("dispose");
    },
    setQueryParam(param) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          ...param
        }
      });
    },
    loadStatusList(reg) {
      for (const p of reg) {
        if (p.piece > 10 && p.piece < 100) {
          this.$refs.elTeeth.addStatus(p);
        } else if (p.piece == 0 || p.piece == 101 || p.piece == 102) {
          this.loadGlobalState(p);
        }
      }
    },

    loadGlobalState(p) {
      // let stateValue = false;
      // if (p.status == 15) {
      //   // this.$refs.csUncovering.addToMakeState(p);
      // } else if (p.status == 16) {
      //   stateValue = true;
      //   // this.$refs.csUncovering.addMakedState(p);
      // } else if (p.status == 23) {
      //   // this.$refs.csWhitening.addToMakeState(p);
      // } else if (p.status == 26) {
      //   stateValue = true;
      //   // this.$refs.csWhitening.addMakedState(p);
      // } else if (p.status == 27) {
      //   // this.$refs.csModels.addToMakeState(p);
      // } else if (p.status == 28) {
      //   stateValue = true;
      //   // this.$refs.csModels.addMakedState(p);
      // } else if (p.status == 29) {
      //   // this.$refs.csRxpanoramic.addToMakeState(p);
      // } else if (p.status == 30) {
      //   stateValue = true;
      //   // this.$refs.csRxpanoramic.addMakedState(p);
      // } else {
      //   stateValue = false;
      // }

      if (p.piece == 0 || p.piece == 101) {
        this.teethStates[p.product_id] = {
          name: p.piece == 0 ? p.product_name : `${p.product_name} (sup)`,
          flag: p.overhall,
          makedTimes:
            (this.teethStates[p.product_id] &&
              this.teethStates[p.product_id].makedTimes) + 1 || 0
        };
      } else if (p.piece == 102) {
        this.teethStatesInf[p.product_id] = {
          name: p.piece == 0 ? p.product_name : `${p.product_name} (inf)`,
          flag: p.overhall,
          makedTimes:
            (this.teethStatesInf[p.product_id] &&
              this.teethStatesInf[p.product_id].makedTimes) + 1 || 0
        };
      }
    },
    getList() {
      DentalClinicService.getTeethHistory({
        client_id: this.client_id,
        name: this.name,
        odontogram_id: this.odontogram_id
      }).then(odo => {
        this.reset();
        this.status = odo.odontogram;
        this.$emit("statusAsigned", odo.odontogram);
        this.loadStatusList(odo.odontogram);
        // if(odo.odontogram[0] && !this.odontogram_id) {
        //   this.setOdontogramId(odo.odontogram[0].odontogram_id);
        // }
        this.names = odo.names;
      });
    },
    deleteItem(id, i) {
      DentalClinicService.deleteTeethHistory(id).then(() => {
        this.status.splice(i, 1);
        this.reset();
        this.loadStatusList(this.status);
        this.$refs.dDeleteItem.hide();
      });
    },
    saveTeethStatus({ product_id, status, piece, onlyOne }) {
      this.saveChange({
        product_id,
        status,
        piece,
        onlyOne
      });
    },
    saveChange(reg) {
      if (reg.overhall && reg.overhall === true) {
        reg.overhall = 1;
      }

      DentalClinicService.saveToothStatus({
        ...reg,
        client_id: this.client_id,
        name: this.name
      }).then(
        list => {
          for (let toothStatus of list) {
            this.$emit("statusAdded", toothStatus);
            this.status.push(toothStatus);
          }

          this.loadStatusList(list);
          this.$refs.dChangeStatus.hide();
        },
        () => {
          alert("hubo un error, no se pudo guardar");
        }
      );
    },
    handleMenu({ piece, status, product_id, pieces, onlyOne }) {
      this.$refs.fChangeStatus.reset();
      this.$refs.fChangeStatus
        .loadReg({
          piece,
          status,
          product_id,
          onlyOne
        })
        .subscribe(
          seeForm => {
            if (seeForm) {
              this.$refs.dChangeStatus.show();
            }
          },
          null,
          () => {
            this.$refs.fChangeStatus.save(pieces);
          }
        );
    },
    reset() {
      this.teethStatesInf = {};
      this.teethStates = {};
      return new Promise(rsv => {
        this.$refs.elTeeth.reset();
        rsv();
      });
    }
  }
};
</script>

<style></style>
