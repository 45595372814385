<template>
  <div>
    <ComOdontogram ref="com_odontogram"></ComOdontogram>
    <div class="row mb-1">
      <div class="col">
        <label for>Producto</label>
        <SelectProduct
          ref="selPro"
          v-model="reg.product_id"
          :categorieId="reg.categorie_id"
        ></SelectProduct>
      </div>
      <div class="col">
        <label for>Categoria</label>
        <SelectCategorie v-model="reg.categorie_id"></SelectCategorie>
      </div>
    </div>
    <div class="mb-1">
      <button class="btn btn-primary mr-1" @click="addProducts()">
        Agregar
      </button>
      <button class="btn btn-primary mr-1" @click="addProducts(2)">
        Agregar 1x1
      </button>
    </div>
    <table class="table table-sm">
      <thead>
        <tr>
          <th></th>
          <th>Producto</th>
          <th>Precio</th>
          <th>Laboratorio</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, index) in details" :key="index">
          <td>{{ index + 1 }}</td>
          <td>
            <span>{{ l.product_name }}</span>
            <br />
            <ToothPiece
              v-for="l1 in l.th_list"
              :key="'m' + l1.piece"
              :piece="l1.piece"
            ></ToothPiece>
          </td>
          <td>
            <app-span-money
              :quantity="l.unit_price"
              :moneyCode="money_code"
            ></app-span-money>
          </td>
          <td>
            <SelectSupplierByProduct
              class="custom-select-sm"
              v-model="l.supplier_id"
              :dontShowIfNotSup="true"
              :product_id="l.product_id"
            ></SelectSupplierByProduct>
          </td>
          <td></td>
          <td>
            <button
              class="btn btn-light btn-sm"
              @click="details.splice(index, 1)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <label for>Descuento (%)</label>
    <div class="form-group form-inline">
      <app-input-number v-model="reg.discount"></app-input-number>
    </div>
    <app-button-submit
      @click="saveTotreatment()"
      text="Cargar a este tratamiento"
    ></app-button-submit>
  </div>
</template>

<script>
import ComOdontogram from "../com-odontogram/Odontogram";
import SelectProduct from "../../store-module/products/Select";
import SelectCategorie from "../../general-module/categories/Select";
import ToothPiece from "../odontogram/ToothPiece";
import { DentalClinicService } from "../service";
import { mapState } from "vuex";
import SelectSupplierByProduct from "../../logistic-module/supplier-products/SelectSupplierByProduct";

export default {
  components: {
    ComOdontogram,
    SelectProduct,
    SelectCategorie,
    ToothPiece,
    SelectSupplierByProduct
  },
  props: {
    treatment_plan_id: {},
    money_code: {},
    proform_id: {}
  },
  data: () => ({
    details: [],
    reg: {
      discount: 0
    }
  }),
  computed: {
    ...mapState({
      config: (s) => s.config.dental.odontogram
    })
  },
  methods: {
    reset() {
      this.details = [];
      this.reg = {
        discount: 0
      };
      this.$refs.selPro.reset();
    },
    saveTotreatment() {
      DentalClinicService.saveComOdoToTreatment({
        treatment_plan_id: this.treatment_plan_id,
        discount: this.reg.discount,
        proform_id: this.proform_id,
        details: this.details
      }).then((res) => {
        this.$emit("submitted", res);
      });
    },
    addProducts(mode = 1) {
      if (mode == 1) {
        let list = this.$refs.com_odontogram.getSelectedList();
        let oTre = {
          product_id: this.$refs.selPro.val.id,
          product_name: this.$refs.selPro.val.name,
          unit_price: this.$refs.selPro.val.unit_price,
          th_list: list.map((x) => ({ ...x, product_id: this.reg.product_id }))
        };
        this.details.push(oTre);
      } else if (mode == 2) {
        let list = this.$refs.com_odontogram.getSelectedList();
        list.map((x) => {
          this.details.push({
            product_id: this.$refs.selPro.val.id,
            product_name: this.$refs.selPro.val.name,
            unit_price: this.$refs.selPro.val.unit_price,
            th_list: [{ ...x, product_id: this.reg.product_id }]
          });
        });
      }
      this.$refs.com_odontogram.reset();
    }
  }
};
</script>

<style></style>
