<template>
  <div class="btn m-0 p-0 dropleft show" ref="elWrapper">
    <!-- 
      @mouseleave="hideMenu()"
      @mousedown="beginCount()"
      @mouseup="handlePress()"
    -->
    <div class="app-odo-t" ref="dropDown" @click.prevent="handleClick($event)">
      <div v-show="isHigher" class="mt-1 app-odo-t-box-i" hidden>
        <b>{{ letters }}&nbsp;</b>
      </div>

      <div v-show="isLower" class="mb-1">
        <div class="text-center w-100 mb-2">
          <a href="#" @click.prevent="$emit('showPiece', piece)">{{ piece }}</a>
        </div>
        <div class="app-odo-t-row">
          <div class="app-odo-t-box"></div>
          <div class="app-odo-t-box app-odo-t-box-i" ref="tf12_i"></div>
          <div class="app-odo-t-box"></div>
        </div>
        <div class="app-odo-t-row">
          <div class="app-odo-t-box app-odo-t-box-i" ref="tf21_i"></div>
          <div class="app-odo-t-box app-odo-t-box-i" ref="tf22_i"></div>
          <div class="app-odo-t-box app-odo-t-box-i" ref="tf23_i"></div>
        </div>
        <div class="app-odo-t-row">
          <div class="app-odo-t-box"></div>
          <div class="app-odo-t-box app-odo-t-box-i" ref="tf32_i"></div>
          <div class="app-odo-t-box"></div>
        </div>
      </div>

      <div style="position: relative;">
        <div class="mb-1 app-odo-tooth-bg">
          <div ref="imgTooth" class="app-odo-tooth-img" v-show="!missing">
            <div ref="imgPulp" class="app-odo-pulp app-odo-pulp-img">
              &nbsp;
            </div>
          </div>
          <div class="app-odo-t-sis" title="Implante" v-show="implants">
            &nbsp;
          </div>
          <div class="app-odo-t-sii" title="Implante" v-show="implanti">
            &nbsp;
          </div>
          <div
            class="app-odo-t-tic"
            title="Corona"
            v-show="toInsCrown"
            :class="{
              'app-odo-t-tic_s': isHigher,
              'app-odo-t-tic_i': isLower
            }"
          >
            &nbsp;
          </div>

          <div
            class="app-odo-t-tii"
            title="Corona"
            v-show="toInsImpl"
            :class="{
              'app-odo-t-tii_s': isHigher,
              'app-odo-t-tii_i': isLower
            }"
          >
            &nbsp;
          </div>

          <div class="app-odo-t-scs" title="Corona" v-show="crowns">&nbsp;</div>
          <div class="app-odo-t-sci" title="Corona" v-show="crowni">&nbsp;</div>

          <div
            class="app_tooth_base_bg "
            :class="{
              app_tooth_state_17_s: isHigher,
              app_tooth_state_17_i: isLower
            }"
            v-show="rxPeriapical"
            title="Rx Periapical"
          ></div>

          <div
            class="app-odo-t-state-base"
            title="Poste para instalar"
            :class="{
              'app-odo-t-ssto-is': isHigher,
              'app-odo-t-ssto-ii': isLower
            }"
            v-show="stubBoltToIns"
          >
            &nbsp;
          </div>

          <div
            class="app-odo-t-state-base"
            title="Perno"
            v-show="stubBolt"
            :class="{
              'app-odo-t-ssbs': isHigher,
              'app-odo-t-ssbi': isLower
            }"
          ></div>

          <!-- <div class="app-odo-t-ssbs" title="Perno" v-show="stubBolts">
            &nbsp;
          </div>
          <div class="app-odo-t-ssbi" title="Perno" v-show="stubBolti">
            &nbsp;
          </div> -->
          <div class="app-odo-t-te" title="Para extraer" v-show="toExtraction">
            &nbsp;
          </div>
          <div
            class="app-odo-t-sf"
            title="fractura"
            :class="{
              'app-odo-t-sf_i': isLower,
              'app-odo-t-sf_s': isHigher
            }"
            v-show="fractured"
          >
            &nbsp;
          </div>
          <div class="app-odo-t-tsi" title="Para Cirugia" v-show="toSurgeryi">
            &nbsp;
          </div>
          <div class="app-odo-t-tss" title="Para Cirugia" v-show="toSurgerys">
            &nbsp;
          </div>
          <div
            class="app-odo-t-state-base app-odo-t-smp"
            title="Mantenedor de espacio"
            v-show="mSpacer"
          >
            &nbsp;
          </div>
          <div
            class="app-odo-t-state-base "
            :class="{
              app_tooth_status_37_i: isLower,
              app_tooth_status_37_s: isHigher
            }"
            title="Carillas dentales para instalar"
            v-show="dentalVeneersToMake"
          >
            &nbsp;
          </div>

          <div
            class="app-odo-t-state-base"
            :class="{
              app_tooth_status_38_i: isLower,
              app_tooth_status_38_s: isHigher
            }"
            title="Carillas dentales"
            v-show="dentalVeneers"
          >
            &nbsp;
          </div>

          <div
            class="app-odo-t-state-base"
            :class="{
              app_tooth_status_39_i: isLower,
              app_tooth_status_39_s: isHigher
            }"
            title="Coronas provisionales para instalar"
            v-show="toInsPreCrown"
          >
            &nbsp;
          </div>

          <div
            class="app-odo-t-state-base"
            :class="{
              app_tooth_status_40_i: isLower,
              app_tooth_status_40_s: isHigher
            }"
            title="Coronas provisionales"
            v-show="preCrown"
          >
            &nbsp;
          </div>

          <div
            class="app-odo-t-state-base"
            :class="{
              app_tooth_status_24_i: isLower,
              app_tooth_status_24_s: isHigher
            }"
            title="Protesis Parcial"
            v-show="ppr"
          >
            &nbsp;
          </div>
          <div
            class="app-odo-t-state-base"
            :class="{
              app_tooth_status_41_i: isLower,
              app_tooth_status_41_s: isHigher
            }"
            title="PPR para instalar"
            v-show="pprToIns"
          >
            &nbsp;
          </div>
          <div
            class="app-odo-t-state-base"
            :class="{
              app_tooth_status_43_i: isLower,
              app_tooth_status_43_s: isHigher
            }"
            title="linea azul"
            v-show="hLineBlue"
          >
            &nbsp;
          </div>
          <div
            class="app-odo-t-state-base"
            :class="{
              app_tooth_status_42_i: isLower,
              app_tooth_status_42_s: isHigher
            }"
            title="linea roja"
            v-show="hLineRed"
          >
            &nbsp;
          </div>

          <div
            class="app-odo-t-state-base"
            :class="{
              app_tooth_status_45_i: isLower,
              app_tooth_status_45_s: isHigher
            }"
            title="RAR"
            v-show="raspaje"
          >
            &nbsp;
          </div>

          <div
            class="app-odo-t-state-base"
            :class="{
              app_tooth_status_44_i: isLower,
              app_tooth_status_44_s: isHigher
            }"
            title="linea roja"
            v-show="raspajeToIns"
          >
            &nbsp;
          </div>
        </div>
      </div>

      <div v-show="isHigher" class="mt-1">
        <div class="app-odo-t-row">
          <div class="app-odo-t-box"></div>
          <div class="app-odo-t-box app-odo-t-box-i" ref="tf12_s"></div>
          <div class="app-odo-t-box"></div>
        </div>
        <div class="app-odo-t-row">
          <div class="app-odo-t-box app-odo-t-box-i" ref="tf21_s"></div>
          <div class="app-odo-t-box app-odo-t-box-i" ref="tf22_s"></div>
          <div class="app-odo-t-box app-odo-t-box-i" ref="tf23_s"></div>
        </div>
        <div class="app-odo-t-row">
          <div class="app-odo-t-box"></div>
          <div class="app-odo-t-box app-odo-t-box-i" ref="tf32_s"></div>
          <div class="app-odo-t-box"></div>
        </div>
        <div class="text-center w-100 mt-1">
          <a href="#" @click.prevent="$emit('showPiece', piece)">{{ piece }}</a>
        </div>
      </div>

      <div v-show="isLower" class="mt-1 app-odo-t-box-i" hidden>
        <b>{{ letters }}&nbsp;</b>
      </div>
    </div>
    <div class="dropdown-menu" v-show="!disabled">
      <template v-if="odoGroup == undefined">
        <a
          v-for="(l, index) in toothStatus"
          :key="index"
          class="dropdown-item"
          href="#"
          @click.prevent="changeStatus(index)"
          v-show="l.show"
        >
          <div class="app-odo-t-m-icon-container" v-show="l.class_icon">
            <div class="app_den_odo_t_icon" :class="l.class_icon">&nbsp;</div>
          </div>
          <span>{{ l.name }}</span>
        </a>
      </template>
      <template v-else>
        <a
          href="#"
          v-for="l in menu"
          :key="l.id"
          class="dropdown-item"
          @click.prevent="setSubMenu(l)"
          v-show="
            odoGroup == undefined || l.group == undefined || odoGroup == l.group
          "
        >
          <div class="app-odo-t-m-icon-container" v-show="l.class_icon">
            <div class="app-odo-t-m-icon" :class="l.class_icon">&nbsp;</div>
          </div>
          <span>&nbsp;{{ l.name }}</span>
        </a>
      </template>

      <div class="dropdown-divider"></div>
      <a
        href="#"
        class="dropdown-item"
        @click.prevent="$emit('showPiece', piece)"
      >
        Ver historial de la pieza
      </a>
      <a class="dropdown-item" href="#" @click.prevent="changeStatus(1)">
        Otro
      </a>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { isLower, isHigher } from "./tooth-utils";
import { DentalClinicService } from "../service";
import { myMoment } from "../../utils/myMoment";

let rootMenu = [];

DentalClinicService.getOdontogramMenu({ all: 1 }).then(res => {
  rootMenu = res.filter(x => x.piece != 0);
});

export default {
  components: {},
  props: {
    options: {
      default: () => {
        return {
          mode: 1,
          group: 1,
          disabled: false
        };
      }
    },
    piece: {
      required: true
    }
    // disabled: {
    //   default: false
    // }
  },
  data: () => ({
    marked: false,

    menu: rootMenu,
    status: [],
    imgClass: "",
    imgClassP: "",
    letters: "",
    missing: false,
    toExtraction: false,
    fractured: false,
    implant: false,
    crown: false,
    toInsPreCrown: false,
    preCrown: false,
    stubBolt: false,
    toSurgery: false,
    mSpacer: false,
    stubBoltToIns: false,
    rxPeriapical: false,
    dentalVeneersToMake: false,
    dentalVeneers: false,
    ppr: false,
    pprToIns: false,
    hLineBlue: false,
    hLineRed: false,
    raspaje: false,
    raspajeToIns: false,

    /** Desfazado */
    implants: false,
    implanti: false,
    crowns: false,
    crowni: false,
    // stubBolts: false,
    // stubBolti: false,
    toSurgeryi: false,
    toSurgerys: false,
    toInsCrown: false,
    toInsImpl: false,

    lastMouseDownTime: null
  }),
  computed: {
    disabled() {
      return this.options.disabled;
    },
    odoGroup() {
      // return this.$store.state.dental.odo_group;
      return this.options.group;
    },
    toothStatus() {
      return this.$store.state.config.dental
        ? this.$store.state.config.dental.odontogram.teeth_status_list
        : [];
    },
    imgClassTooth() {
      return "app_den_odo_t_" + this.piece;
    },
    imgClassPulp() {
      return "app_den_odo_p_" + this.piece;
    },
    isLower() {
      return isLower(this.piece);
    },
    isHigher() {
      return isHigher(this.piece);
    },
    tf12: {
      get() {
        return isHigher(this.piece) ? this.$refs.tf12_s : this.$refs.tf12_i;
      }
    },
    tf21: {
      get() {
        return isHigher(this.piece) ? this.$refs.tf21_s : this.$refs.tf21_i;
      }
    },
    tf22: {
      get() {
        return isHigher(this.piece) ? this.$refs.tf22_s : this.$refs.tf22_i;
      }
    },
    tf23: {
      get() {
        return isHigher(this.piece) ? this.$refs.tf23_s : this.$refs.tf23_i;
      }
    },
    tf32: {
      get() {
        return isHigher(this.piece) ? this.$refs.tf32_s : this.$refs.tf32_i;
      }
    }
  },
  watch: {
    /**
     *
     * Clase que contiene las coordenadas del diente
     *
     */
    imgClass(to, from) {
      if (from) {
        this.$refs.imgTooth.classList.remove(from);
      }
      this.$refs.imgTooth.classList.add(to);
    },
    /**
     *
     * Clase que contiene las coordenadas de la
     * pulpa del diente
     *
     */
    imgClassP(to, from) {
      if (from) {
        this.$refs.imgPulp.classList.remove(from);
      }
      this.$refs.imgPulp.classList.add(to);
    }
  },
  mounted() {
    this.imgClass = this.imgClassTooth;
    this.imgClassP = this.imgClassPulp;
  },
  methods: {
    handleClick() {
      if (this.options.mode == 1) {
        this.showMenu();
      } else if (this.options.mode == 2) {
        this.toggleMarked();
      }
    },
    beginCount() {
      this.lastMouseDownTime = myMoment();
    },
    handlePress() {
      if (myMoment().diff(this.lastMouseDownTime, "milliseconds") >= 1000) {
        this.toggleMarked();
      }
    },
    toggleMarked() {
      this.setMarked(!this.marked);
    },
    setMarked(val) {
      this.marked = val;

      if (this.marked) {
        this.$emit("marked", this.piece);
        this.$refs.elWrapper.classList.add("bg-danger");
      } else {
        this.$emit("unmarked", this.piece);
        this.$refs.elWrapper.classList.remove("bg-danger");
      }
    },
    statusMember(status) {
      let list = this.$store.state.config.dental
        ? this.$store.state.config.dental.odontogram.teeth_status_list
        : [];
      return list[status] ? list[status] : {};
    },
    /** Navegacion a un sub menu */
    setSubMenu(l) {
      if (l.type_id == 2) {
        this.menu = rootMenu.filter(x => {
          return x.menu_id == l.id;
        });
        this.$forceUpdate();
      } else {
        this.changeStatus(l.status, l.product_id, l.piece);
      }
    },
    /** Mostrar un menu */
    showMenu() {
      $(".app-odo-t").dropdown("hide");

      // $(this.$refs.dropDown).dropdown("toggle");
      this.menu = rootMenu.filter(x => {
        return (
          x.group == this.odoGroup &&
          (x.menu_id == undefined || x.menu_id == null)
        );
      });

      $(this.$refs.dropDown).dropdown("show");
    },
    /** Ocultar el menu */
    hideMenu() {
      // $(this.$refs.dropDown).dropdown("hide");
      // $(this.$refs.dropDown).dropdown('dispose')
      $(".app-odo-t").dropdown("hide");
      $(".app-odo-t").dropdown("dispose");
    },
    /**
     *
     * Emite el evento para guardar cambio de estado
     *
     */
    changeStatus(status, product_id, piece) {
      // console.log("changestatus", this.statusMember.piece, this.piece);
      this.$emit("clickMenu", {
        piece: piece || this.piece,
        status,
        product_id
      });
      this.hideMenu();
    },
    colorFlag(flag) {
      return this.$store.state.config.dental
        ? this.$store.state.config.dental.odontogram.teeth_flag_colors[flag]
        : "";
    },
    reset() {
      this.setMarked(false);
      this.toInsPreCrown = false;
      this.preCrown = false;
      this.ppr = false;
      this.pprToIns = false;
      this.hLineBlue = false;
      this.hLineRed = false;
      this.imgClass = this.imgClassTooth;
      this.imgClassP = this.imgClassPulp;
      this.missing = false;
      this.toExtraction = false;
      this.fractured = false;
      this.implants = false;
      this.implanti = false;
      this.crowns = false;
      this.crowni = false;
      this.stubBolts = false;
      this.stubBolti = false;
      this.toSurgeryi = false;
      this.toSurgerys = false;
      this.toInsCrown = false;
      this.toInsImpl = false;
      this.stubBoltToIns = false;
      this.raspaje = false;
      this.raspajeToIns = false;
      this.tf12.style.backgroundColor = this.colorFlag(0);
      this.tf21.style.backgroundColor = this.colorFlag(0);
      this.tf22.style.backgroundColor = this.colorFlag(0);
      this.tf23.style.backgroundColor = this.colorFlag(0);
      this.tf32.style.backgroundColor = this.colorFlag(0);
      this.status = [];
      this.mSpacer = false;
      this.letters = "";

      this.rxPeriapical = false;
      this.dentalVeneersToMake = false;
      this.dentalVeneers = false;
    },
    addStatus(reg) {
      this.setMarked(false);

      if (reg.status == 45) {
        this.raspaje = true;
        this.raspajeToIns = false;
      }

      if (reg.status == 44) {
        this.raspaje = false;
        this.raspajeToIns = true;
      }

      // 24 y 41
      if (reg.status == 24) {
        this.ppr = true;
        this.pprToIns = false;
      }

      if (reg.status == 41) {
        this.ppr = false;
        this.pprToIns = true;
      }

      // 42 y 43
      if (reg.status == 42) {
        this.hLineBlue = false;
        this.hLineRed = true;
      }

      if (reg.status == 43) {
        this.hLineBlue = true;
        this.hLineRed = false;
      }

      /** corona provisional */
      if (reg.status == 39) {
        this.toInsPreCrown = true;
        this.preCrown = false;
      }

      if (reg.status == 40) {
        this.toInsPreCrown = false;
        this.preCrown = true;
      }

      /** Caries y restauraciones */
      if (reg.status == 2) {
        this.imgClass = "app_den_odo_t_" + this.piece + "_c";
      } else if (reg.status == 9) {
        this.imgClass = "app_den_odo_t_" + this.piece + "_r";
      }

      /** Infecciones y endodoncias */
      if (reg.status == 3) {
        this.imgClassP = "app_den_odo_p_" + this.piece + "_i";
      } else if (reg.status == 10) {
        this.imgClassP = "app_den_odo_p_" + this.piece + "_e";
      }

      /** Fractura */
      if (reg.status == 4) {
        this.fractured = true;
      }

      /** Ausente */
      if (reg.status == 11) {
        this.missing = true;
      } else if (reg.status == 5) {
        this.toExtraction = true;
      }

      /** Perno para instalar */
      if (reg.status == 21) {
        this.stubBoltToIns = true;
        this.stubBolt = false;
      }

      /** Perno */
      if (reg.status == 14) {
        this.stubBolt = true;
        this.stubBoltToIns = false;
      }

      /** Coronas e implantes */
      if (this.isHigher) {
        if (reg.status == 12) {
          this.crowns = true;
          this.toInsCrown = false;
        }
        if (reg.status == 13) {
          this.implants = true;
        }
        // if (reg.status == 14) {
        //   this.stubBolts = true;
        // }
      } else {
        if (reg.status == 12) {
          this.crowni = true;
          this.toInsCrown = false;
        }
        if (reg.status == 13) {
          this.implanti = true;
        }
        // if (reg.status == 14) {
        //   this.stubBolti = true;
        // }
      }

      /** Corona para instalar */
      if (reg.status == 6) {
        this.toInsCrown = true;
      }
      /** implante para instalar */
      if (reg.status == 7) {
        this.toInsImpl = true;
      }

      if (reg.status == 8) {
        if (this.isLower) this.toSurgeryi = true;
        else this.toSurgerys = true;
      }

      /** Mantenedor de espacio */
      if (reg.status == 20) {
        this.mSpacer = true;
      }

      /**rx periapical */
      if (reg.status == 17) {
        this.rxPeriapical = true;
      }

      if (reg.overhall && (reg.status == 2 || reg.status == 9)) {
        this.tf12.style.backgroundColor = this.colorFlag(reg.overhall);
        this.tf21.style.backgroundColor = this.colorFlag(reg.overhall);
        this.tf22.style.backgroundColor = this.colorFlag(reg.overhall);
        this.tf23.style.backgroundColor = this.colorFlag(reg.overhall);
        this.tf32.style.backgroundColor = this.colorFlag(reg.overhall);
      } else {
        this.tf22.style.backgroundColor = this.colorFlag(reg.oclusal);
        if (
          (this.piece >= 10 && this.piece < 20) ||
          (this.piece >= 40 && this.piece < 50) ||
          (this.piece >= 50 && this.piece < 60) ||
          (this.piece >= 80 && this.piece < 90)
        ) {
          this.tf23.style.backgroundColor = this.colorFlag(reg.mesial);
          this.tf21.style.backgroundColor = this.colorFlag(reg.distal);
        } else {
          this.tf21.style.backgroundColor = this.colorFlag(reg.mesial);
          this.tf23.style.backgroundColor = this.colorFlag(reg.distal);
        }

        if (
          (this.piece >= 10 && this.piece < 20) ||
          (this.piece >= 20 && this.piece < 30) ||
          (this.piece >= 50 && this.piece < 60) ||
          (this.piece >= 60 && this.piece < 70)
        ) {
          this.tf12.style.backgroundColor = this.colorFlag(reg.vestibular);
          this.tf32.style.backgroundColor = this.colorFlag(reg.palatino);
        } else {
          this.tf32.style.backgroundColor = this.colorFlag(reg.vestibular);
          this.tf12.style.backgroundColor = this.colorFlag(reg.lingual);
        }
      }

      if (reg.status == 37) {
        this.dentalVeneersToMake = true;
        this.dentalVeneers = false;
      }

      if (reg.status == 38) {
        this.dentalVeneers = true;
        this.dentalVeneersToMake = false;
      }
    }
  }
};
</script>

<style>
@import "./tooth-style.scss";
@import "./tooth-icons.scss";
</style>
